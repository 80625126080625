<template>
  <Widget :config="widgetConfig"/>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { applyPureReactInVue } from 'veaury';
import { LiFiWidget } from '@lifi/widget';
import Web3 from 'web3';

import { useAuthStore } from '@/store/auth';
import { useBankingStore } from '@/store/banking';
import { useBlockchainConfigStore } from '@/store/blockchainConfig';
import { Log } from '@/core/logger';

const props = defineProps({
  fromNetwork: {
    type: String,
    default: null,
  },
  toNetwork: {
    type: String,
    default: null,
  },
  fromCurrency: {
    type: String,
    default: null,
  },
  toCurrency: {
    type: String,
    default: null,
  },
  amount: {
    type: [String, Number,],
    default: null,
  },
  lock: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'expandable',
  },
});

const authStore = useAuthStore();
const { address, wallet, } = storeToRefs(authStore);

const bankingStore = useBankingStore();
const { balances, } = storeToRefs(bankingStore);

const { configs: networkConfigs, } = useBlockchainConfigStore();

const Widget = applyPureReactInVue(LiFiWidget);

const signer = {
  getAddress() {
    Log.log('LiFi Signer Get Address');
    return address.value;
  },
  getChainId() {
    Log.log('LiFi Signer Get Chain ID');
    return Web3.utils.hexToNumber(wallet.value.chainId);
  },
  async sendTransaction(data) {
    delete data.gasPrice;

    return await wallet.value.provider.request({
      method: 'eth_sendTransaction',
      params: [data,],
    });
  },
};

const fromNetwork = props.fromNetwork;
const toNetwork = props.toNetwork;
const fromCurrency = props.fromCurrency;
const toCurrency = props.toCurrency;
const lock = props.lock;
let fromToken = null;
let toToken = null;

const fromChainId = fromNetwork ? networkConfigs[fromNetwork]?.chainId : undefined;
const toChainId = toNetwork ? networkConfigs[toNetwork]?.chainId : undefined;
const isProd = ['Prod', 'QA',].includes(getConfig('ENV_NAME'));
const web3Balances = balances.value.filter(b => b.context === 'Default' && !!b.config?.networks);

const chainIds = [];
const tokens = [];

for (const balance of web3Balances) {
  for (const [network, config,] of Object.entries(balance.config.networks)) {
    if (!Web3.utils.isHex(networkConfigs[network]?.chainId)) {
      continue;
    }

    const chainId = Web3.utils.hexToNumber(networkConfigs[network]?.chainId);
    const address = config.assetType === 'Token' ? config.address : '0x0000000000000000000000000000000000000000';

    if (!chainId) {
      continue;
    }

    if (!chainIds.includes(chainId)) {
      chainIds.push(chainId);
    }

    if (tokens.find(t => t.chainId === chainId && t.address === address)) {
      continue;
    }

    tokens.push({
      address,
      chainId,
    });
  }
}

function getToken(currency, network) {
  if (currency && network) {
    const balance = balances.value.find(b => b.name === currency && b.context === 'Default');

    if (balance) {
      const config = balance.config;
      const networkConfig = config.networks[network];

      if (networkConfig?.assetType === 'Token' && networkConfig?.address) {
        return networkConfig.address;
      }

      return balance.name;
    }
  }
}

fromToken = getToken(fromCurrency, fromNetwork);
toToken = getToken(toCurrency, toNetwork);

const widgetConfig = {
  integrator: 'MetaWin',
  fromChain: fromChainId && isProd ? Web3.utils.hexToNumber(fromChainId) : undefined,
  fromToken,
  toChain: toChainId && isProd ? Web3.utils.hexToNumber(toChainId) : undefined,
  toToken,
  fromAmount: props.amount,
  variant: props.variant,
  chains: {
    types: {
      allow: ['EVM', 'SVM',],
    },
  },
  tokens: tokens.length > 0
    ? {
      allow: tokens,
    }
    : undefined,
  walletManagement: {
    connect() {
      Log.log('LiFi Connect');
    },
    disconnect() {
      Log.log('LiFi Disconnect');
    },
    async switchChain(chainId) {
      Log.log('LiFi Switch Chain', chainId);
      await wallet.value.checkChain(chainId);
      return signer;
    },
    signer,
  },
  disabledUI: isProd && toNetwork && lock ? ['toToken',] : [],
  hiddenUI: ['toAddress', 'appearance', 'walletMenu',],
  appearance: 'dark',
  containerStyle: {
    height: 'auto',
    borderRadius: '0.75rem',
  },
  theme: {
    palette: {
      primary: { main: '#1475E1', },
      secondary: { main: '#1475E1', },
      background: {
        default: '#0F1115',
        paper: '#0F1115',
      },
    },
    typography: {
      fontFamily: 'Inter',
    },
  },
};
</script>
